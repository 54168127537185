/*=============================================
=            Section Title            =
=============================================*/
.section-title {
  font-size: 18px;

  position: relative;
  a {
    font-size: 10px;
    font-weight: 400;

    position: absolute;
    top: 50%;
    left: 0;

    transform: translateY(-50%);
    span {
      div {
        display: inline-block;
      }
    }
  }
}

/*=====  End of Section Title  ======*/
